import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page-404">
      <Container>
        <h1>404 NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
        <Link className="btn" to="/">Go To Home</Link>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
